interface PDFViewerProps {
  pdf: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdf }) => {
  return (
    <div className="hs vs-xl mw">
      <div className="pdf-viewer" dangerouslySetInnerHTML={{ __html: pdf }} />
    </div>
  );
};

export default PDFViewer;
